export default {
"title": "🇩🇪 Deutsch",

"首页": "Startseite",
"关于": "Über uns",
"会员": "Mitgliedschaft",
"推荐计划": "Empfehlungsprogramm",
"个人": "Profil",
"推荐": "Empfehlung",
"预订": "Jetzt reservieren",
"事件": "Veranstaltung",
"登出": "Abmelden",
"登录": "Anmelden",
"注册": "Registrieren",
"我们的应用程序现在可用": "Unsere App ist jetzt verfügbar",
"为了获得更好的体验，我们刚刚推出了移动应用程序，以实现无缝、更快的预订。": "Für ein besseres Erlebnis haben wir gerade unsere mobile App für nahtlose und schnellere Buchungen gestartet.",
"iOS版下载（即将推出）": "Download für iOS (kommt bald)",
"Android版下载（即将推出）": "Download für Android (kommt bald)",
"为什么选择我们": "Warum uns wählen",
"我们卓越的服务。": "Unsere herausragenden Dienstleistungen.",
"收入最高的酒店": "Top-Attraktionen mit den höchsten Einnahmen",
"我们的合作酒店收入最高。": "Unsere Partner-Attraktionen mit den höchsten Einnahmen.",
"回报": "Renditen",
"最具特色的酒店": "Meistempfohlene Attraktionen",
"我们平台上最有特色的酒店。": "Die meistempfohlenen Hotels auf unserer Plattform.",
"热门国家和目的地": "Top-Länder & Reiseziele",
"从我们的合作伙伴和朋友那里了解一些来自城市各地的最佳提示。": "Entdecken Sie einige der besten Tipps aus der ganzen Stadt von unseren Partnern und Freunden.",
"酒店": "Attraktionen",
"预订酒店并开始赚取": "Entdecken Sie die Welt mit uns!",
"让我们开始与TitanTraveler一起探索世界": "Machen Sie sich bereit, die Welt der Attraktionen mit uns zu erkunden!",
"客户评价": "Kundenbewertungen",
"查看我们用户的真实评价。": "Sehen Sie sich unsere authentischen Testimonials von unseren Nutzern an.",
"银行卡列表": "Bankkartenliste",
"银行列表": "Bankliste",
"持卡人姓名": "Name des Karteninhabers",
"银行卡号": "Bankkontonummer",
"支付密码": "Zahlungspasswort",
"提交": "Absenden",
"Pix列表": "Pix-Liste",
"Pix类型": "Pix-Typ",
"Pix账号": "Pix-Kontonummer",
"账号列表": "Kontoliste",
"网络": "Netzwerk",
"地址": "Adresse",
"备注": "Bemerkung",
"充值金额": "Aufladebetrag",
"支付类型": "Zahlungsart",
"支付银行": "Zahlungsbank",
"USDT地址": "USDT-Adresse",
"二维码": "QR-Code",
"充值渠道": "Aufladekanal",
"充值凭证": "Aufladenachweis",
"立即登录": "Jetzt anmelden",
"用户名": "Benutzername",
"密码": "Passwort",
"邀请码": "Einladungscode",
"确认密码": "Passwort bestätigen",
"手机": "Handy",
"注册2": "Registrieren",
"金额": "Betrag",
"新增": "Neu",
"我们的精彩": "Unser Großartiges",
"故事": "Geschichte",
"奖项": "Auszeichnungen",
"获得": "Erhalten",
"钱包余额": "Wallet-Guthaben",
"今日奖励": "Heutige Belohnungen",
"总行程": "Gesamtreise",
"访问行程": "Besuchte Reisen",
"酒店历史": "Reservierungshistorie",
"单价": "Preis",
"数量": "Anzahl",
"总额": "Gesamt",
"奖励": "Belohnungen",
"上一页": "Vorherige",
"下一页": "Nächste",
"每套预订量": "Buchungen pro Set",
"预订奖励": "Buchungsbelohnung",
"每日预订": "Tägliche Buchungen",
"手机号码": "Telefonnummer",
"修改密码": "Passwort ändern",
"登录密码": "Anmeldepasswort",
"当前密码": "Aktuelles Passwort",
"新密码": "Neues Passwort",
"确认新密码": "Neues Passwort bestätigen",
"保存修改": "Änderungen speichern",
"充值": "Aufladen",
"我们的": "Unser",
"复制": "Kopieren",
"分享链接": "Link teilen",
"搜索酒店": "Reise reservieren",
"实际等级": "Aktuelles Level",
"升级": "Upgrade",
"已完成预订": "Abgeschlossene Buchungen",
"登录时间": "Anmeldezeit",
"信誉分": "Vertrauensscore",
"控制菜单": "Dashboard-Menü",
"提现": "Auszahlung",
"提现账号": "Auszahlungskonto",
"你确定要删除此账号吗": "Sind Sie sicher, dass Sie dieses Konto löschen möchten?",
"你确定要删除此地址吗": "Sind Sie sicher, dass Sie diese Adresse löschen möchten?",
"提示": "Hinweis",
"是": "Ja",
"否": "Nein",
"全部": "Alle",
"待处理": "Ausstehend",
"冻结中": "Eingefroren",
"已完成": "Abgeschlossen",
"订阅": "Abonnieren",
"希望在我们推出新模板或更新时收到通知。 只需注册，我们就会通过电子邮件向您发送通知。": "Möchten Sie benachrichtigt werden, wenn wir eine neue Vorlage oder ein Update veröffentlichen? Melden Sie sich einfach an und wir senden Ihnen eine Benachrichtigung per E-Mail.",
"我们的伙伴": "Unsere Partner",
"关于我们": "ÜBER UNS",
"实时聊天支持": "Live-Chat-Support",
"在线聊天": "Live-Chat",
"创建时间": "Erstellungszeit",
"你确定要登出当前账号吗？": "Möchten Sie sich abmelden?",
"请输入你的邮箱": "Geben Sie Ihre E-Mail ein",
"好评": "Sehr gut",
"条评论": "Bewertungen",
"平均每晚": "Durchschn./Nacht",
"旅行旅程": "Reisereisen",
"正在寻找酒店": "Suchen Sie eine Reise",
"开始预订": "Reservierung starten",
"旅程价格": "Reisepreis",
"佣金": "Provisionen",
"底部-about": "Wir sind eine Digital-Marketing-Agentur mit Sitz in Brüssel, Belgien, die es Unternehmen ermöglicht, in der digitalen Welt erfolgreich zu sein, durch innovative und datengesteuerte Marketingstrategien, persönlichen Service und eine Wachstumsmentalität, die den Puls der Branchentrends hält. Egal, ob Sie eine SEO-Agentur, Webdesigner, Pay-per-Click-Management, Markenstrategie, Inhaltsentwicklung oder andere digitale Marketingdienstleistungen benötigen, EXPLORE AND SHARE verfügt über die Expertise und Erfahrung, um Ihnen zu helfen, Ihre Ziele in einer ergebnisorientierten Welt zu erreichen.",
"立即预订": "Jetzt buchen",
"搜索": "Suchen",
"快乐的客户": "Glückliche Kunden",
"快乐的酒店老板": "Glückliche Hotelbesitzer",
"评论1": "Architektur an Gebäuden.",
"评论2": "Dies ist ein großartiger Ort für einen Spaziergang mit engen Gassen und einiger schöner Architektur an den Gebäuden.",
"评论3": "Schöner Platz zwischen dem Strand und der Zentralstraße.",
"评论4": "Sehr schöne Gebäude mit einer interessanten Geschichte.",
"评论5": "Viele Restaurants zur Auswahl und eine sehr entspannte Atmosphäre.",
"评论6": "Schönes Land mit lieblichen Orangenhainen und Tieren.",
"评论7": "Toller Ort für einen Wochenendausflug.",
"评论8": "Interessanter Ort ......",
"评论9": "Ein großartiger Ort, um sich zurückzulehnen und die Welt vorbeiziehen zu lassen.",
"评论10": "High-End-Strandclub, der eine schöne Hommage an diejenigen ist, die im Krieg gestorben sind.",
"评论11": "Es gibt so viel zu sehen und es ist ein großartiger Ort, um Urlaub zu machen und sich zu entspannen!",
"评论12": "Zum ersten Mal hier und es war sehr angenehm, werde das nächste Mal wiederkommen.",
"评论13": "Es war eine andere Erfahrung und fühlte sich großartig an, die Einheimischen waren sehr freundlich und höflich!",
"推荐计划1": "Plattformnutzer können andere einladen, über Empfehlungscodes Plattformagenten zu werden und Ihre Downline zu bilden.",
"推荐计划2": "Als Upline können Sie einen bestimmten Prozentsatz des Gewinns Ihrer Downline erhalten, und der von der Upline erzielte Gewinn wird direkt auf das Plattformkonto der Upline oder in den Teambericht zurückgeführt.",
"推荐计划3": "Hinweis: Alle Agenten/Downlines der Plattform erhalten entsprechend einen bestimmten Prozentsatz der Plattformgewinne und -belohnungen. Die Entwicklung eines Teams beeinträchtigt nicht die Gewinne und Belohnungen von Agenten oder Downlines.",
"评分": "Bewertung",
"评论": "Kommentar",
"酒店很棒": "Das Hotel ist großartig",
"终于体验了，酒店非常好": "Endlich erlebt, das Hotel ist sehr gut",
"我对酒店非常满意并推荐预订": "Ich bin mit dem Hotel sehr zufrieden und empfehle die Buchung",
"我很高兴很满意，价格实惠": "Ich bin sehr glücklich und zufrieden, der Preis ist erschwinglich",
"服务非常好，我非常喜欢": "Der Service ist sehr gut, ich mag es sehr",
"体验很不错，朋友已经第二次预订了": "Die Erfahrung war sehr gut, mein Freund hat bereits zum zweiten Mal gebucht",
"酒店环境优美": "Schöne Hotelumgebung",
"酒店卫生干净": "Hotel ist hygienisch und sauber",
"工作人员态度非常好": "Das Personal hat eine sehr gute Einstellung",
"酒店服务很棒": "Der Hotelservice ist großartig",
"你还没有设置支付密码，请前往设置": "Sie haben noch kein Zahlungspasswort festgelegt, bitte gehen Sie zu den Einstellungen",
"订单": "Bestellungen",
"复制成功": "Kopieren erfolgreich",
"团队与条件": "Allgemeine Geschäftsbedingungen",
"站内信": "Systemnachrichten",
"请联系客服升级会员": "Bitte kontaktieren Sie den Kundendienst, um die Mitgliedschaft zu aktualisieren",
"我已阅读并同意": "Ich habe gelesen und stimme zu",
"注册协议": "Registrierungsvereinbarung",
"忘记密码": "Passwort vergessen",
"账户明细": "Kontodetails",
"时间": "Zeit",
"类型": "Typ",
"代理支持": "Agentenunterstützung",
"冻结金额": "Eingefrorener Betrag",
}